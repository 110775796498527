import type { RouteLocationNormalized } from 'vue-router';
import { defineStore } from 'pinia';
import { getIamPermissions } from '@/common/api/getIamPermissions';
import { APIError } from '@/common/errors/APIError';
import { handleThrownError } from '@/common/errors/handleThrownError';
import type { RBACAction, RBACResource } from '@/types';
import type { Permission } from '@/types/Permission';

type PermissionPartial = Partial<Record<RBACResource, RBACAction[]>>;
interface CompanyPermissions {
  permissions?: PermissionPartial;
  isLoading: boolean;
}
type PermissionsPerCompany = Record<string, CompanyPermissions>;

interface PermissionStoreState {
  permissionsPerCompany: PermissionsPerCompany;
}

interface PermissionStoreLoadPermissionsConfig {
  throwError?: boolean;
}

export const usePermissionsStore = defineStore({
  id: 'permissionStore',
  state: (): PermissionStoreState => ({
    permissionsPerCompany: {},
  }),
  actions: {
    async loadPermissions(
      shortname: string,
      config?: PermissionStoreLoadPermissionsConfig,
    ) {
      if (this.permissionsPerCompany[shortname]) {
        return;
      }

      this.permissionsPerCompany[shortname] = {
        isLoading: true,
      };

      try {
        const { status, data, error } = await getIamPermissions(shortname);
        if (status !== 200 || !data) {
          throw new APIError({
            message: 'Failed to load Permissions',
            status,
            error,
          });
        }

        const loadedPermissions: PermissionPartial = {};
        data.forEach(({ actions, resource }) => {
          loadedPermissions[resource] = actions;
        });
        this.permissionsPerCompany[shortname].permissions = loadedPermissions;
      } catch (error: unknown) {
        handleThrownError({
          error,
          toastMessage: 'Failed to load Permissions',
          throw: config?.throwError,
        });
      } finally {
        this.permissionsPerCompany[shortname].isLoading = false;
      }
    },
    can(action: RBACAction, resource: RBACResource, shortname: string) {
      const companyPermissions = this.permissionsPerCompany[shortname];

      if (!companyPermissions || !companyPermissions.permissions) {
        return false;
      }

      return !!companyPermissions.permissions[resource]?.includes(action);
    },
    canVisitRoute(route: RouteLocationNormalized) {
      const shortname = route.params.shortname as string;

      if (!shortname) {
        return true;
      }

      const { requiredPermissions } = route.meta;

      if (!requiredPermissions?.length) {
        // No permissions required, always allowed
        return true;
      }

      return requiredPermissions.every(([action, resource]: Permission) =>
        this.can(action, resource, shortname),
      );
    },
    reset() {
      this.$reset();
    },
  },
});
