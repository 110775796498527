import type { MessageContext } from '@/common/addContextToMessage';
import type { ErrorResponse, ValidationErrorResponse } from '@/types';
import { ErrorWithContext } from './ErrorWithContext';

interface APIErrorConstructor {
  message: string;
  status: number;
  error: ValidationErrorResponse | ErrorResponse | undefined;
  context?: MessageContext;
}

export class APIError extends ErrorWithContext {
  serverError?: ErrorResponse | ValidationErrorResponse;

  constructor(config: APIErrorConstructor) {
    const errorContext: MessageContext = {};

    if (config.error) {
      errorContext.errorMessage = config.error.message;
      errorContext.errorCode = config.error.code;
      errorContext.errorCorrelationId = config.error.correlation_id;
    }

    super(config.message, {
      ...config.context,
      ...errorContext,
      status: config.status,
    });

    this.name = 'APIError';
    this.serverError = config.error;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIError);
    }
  }
}
