import { useToastStore } from '@/stores/useToastStore';
import type { ErrorResponse, ValidationErrorResponse } from '@/types';
import { Logger } from '../Logger';
import { APIError } from './APIError';

interface HandleThrownErrorConfig {
  error: unknown;
  log?: boolean;
  throw?: boolean;
  toastMessage?: string;
  toastDuration?: number;
}

export const handleThrownError = ({
  error,
  toastMessage,
  toastDuration = 5000,
  log = true,
  throw: shouldThrow,
}: HandleThrownErrorConfig) => {
  let serverError: ErrorResponse | ValidationErrorResponse | undefined;

  if (error instanceof APIError) {
    serverError = error.serverError;
  }

  if (toastMessage) {
    useToastStore().error(
      toastMessage,
      toastDuration,
      serverError?.message,
      serverError?.correlation_id,
    );
  }

  if (log) {
    Logger.error(error);
  }

  if (shouldThrow) {
    throw error;
  }
};
